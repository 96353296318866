var YouLi = YouLi ? YouLi : {};
YouLi.Common = YouLi.Common ? YouLi.Common : {};

(function (YouLi) {
    (function (Common) {

        YouLi.Common.PaymentGateway = {
            Stripe: 0,
            Square: 1,
            CyberSource: 2,
            AuthorizeNet: 3,
            PayPal: 4,
        };

        YouLi.Common.SendPaymentReceipt = function (tripId, inviteeId, isPlannerTriggered) {

            YouLi.Common.UI.ShowLoading();

            var data = {
                tripid: tripId,
                inviteeid: inviteeId,
                isPlannerTriggered: isPlannerTriggered ? isPlannerTriggered : false,
            };

            $.ajax({
                url: "/trip/SendInviteeReceipt",
                type: "POST",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data),
                complete: function (response) {
                    YouLi.Common.UI.HideLoading();

                    if (response.status === 500) {
                        YouLi.Common.UI.ShowStandardDialog("An error occurred when sending email.");
                    } else if (response.responseJSON != undefined && response.responseJSON.success == false) {
                        YouLi.Common.UI.ShowStandardDialog("An error occurred when sending email.");
                    }
                    else {
                        let title = response.responseJSON.successMessageTitle;
                        let message = response.responseJSON.message;

                        YouLi.Common.UI.ShowStandardDialog(message, title, "Okay", "success");
                    }
                }
            });
        };

        YouLi.Common.OpenSupportTicket = function (userMessage, debugMessage, subject) {

            $.ajax({
                url: `/trip/opensupportticket?userMessage=${userMessage}&debugMessage=${debugMessage}&subject=${subject}`,
                type: 'POST',
                cache: false,
                contentType: false,
                processData: false
            });

            // note: we don't do anything special on success or fail
        }


        YouLi.Common.UpdateUrlParam = function (key, value) {
            const url = new URL(window.location);
            value = encodeURIComponent(value);
            url.searchParams.set(key, value);
            window.history.pushState({}, '', window.unescape(url));
        }

        YouLi.Common.UpdateUrlParams = function (key, values) {
            let urlParams = new URLSearchParams(window.location.search);
            let existingValues = urlParams.getAll(key);

            existingValues.forEach(function (value) {
                urlParams.delete(key);
            });

            values.forEach(function (value) {
                urlParams.append(key, value);
            });

            window.history.replaceState({}, '', '?' + urlParams.toString());
        }

        YouLi.Common.ClearUrlParams = function (key) {
            const url = window.location.href;

            const regex = new RegExp('([?&])' + key + '=[^&]*');

            const newUrl = url.replace(regex, function (match, p1) {
                return p1 === '?' ? '?' : '';
            });

            window.history.replaceState({}, document.title, newUrl);
        }

        YouLi.Common.GetGAData = function (eventName) {
            var data = {
                event: eventName,
                planner_has_ecommerce: $("#ECommerceDataLayerEnabled").val()

            };
            var items = [
                {
                    item_id: $("#GA_Alias").val(),
                    item_name: $("#GA_TripName").val(),
                    item_variant: $("#GA_PackageName").val(),
                    affiliation: $("#GA_CurrentUserAffiliateCode").val(),
                    currency: $("#GA_CurrencyCode").val(),
                    item_brand: $("#GA_BusinessName").val(),
                    price: $("#GA_CostPerPerson").val(),
                    quantity: $("#GA_Quantity").val(),
                    location_id: $("#GA_PrimaryLocation").val(),
                }
            ];

            if (eventName == "view_item") {
                data.ecommerce = {
                    currency: $("#GA_CurrencyCode").val(),
                    value: $("#GA_CostPrepayTotalOutstanding").val(),
                    items: items
                }
            }

            if (eventName == "select_promotion") {
                data.ecommerce = {
                    promotion_id: $("#SelectedCouponCode").val(),
                    promotion_name: $("#SelectedCouponCode").val(),
                    items: items
                }
            }

            if (eventName == "generate_lead" || eventName == "add_to_cart" || eventName == "remove_from_cart" || eventName == "view_cart") {
                data.ecommerce = {
                    currency: $("#GA_CurrencyCode").val(),
                    value: $("#GA_CostPrepayTotalOutstanding").val(),
                    items: items
                }
            }

            if (eventName == "begin_checkout") {
                data.ecommerce = {
                    currency: $("#GA_CurrencyCode").val(),
                    value: $("#GA_CostPrepayTotalOutstanding").val(),
                    coupon: $("#SelectedCouponCode").val(),
                    tax: $("#GA_Tax").val(),
                    items: items
                }
            }

            if (eventName == "purchase") {
                data.ecommerce = {
                    transaction_id: $("#GA_TransactionId").val(),
                    currency: $("#GA_CurrencyCode").val(),
                    value: $("#GA_CostPrepayTotalOutstanding").val(),
                    coupon: $("#SelectedCouponCode").val(),
                    items: items
                }
            }

            var firstItem = data.ecommerce.items[0];
            if ($("#GA_Tag0").length) {
                firstItem.item_category = $("#GA_Tag0").val();
            }
            if ($("#GA_Tag1").length) {
                firstItem.item_category2 = $("#GA_Tag1").val();
            }
            if ($("#GA_Tag2").length) {
                firstItem.item_category3 = $("#GA_Tag2").val();
            }
            if ($("#GA_Tag3").length) {
                firstItem.item_category4 = $("#GA_Tag3").val();
            }
            if ($("#GA_Tag4").length) {
                firstItem.item_category5 = $("#GA_Tag4").val();
            }

            return data;
        }

        YouLi.Common.Get = function (url, params, successCallback, errorCallback, datatype = "json", cache = false) {
            $.ajax({
                url: buildQueryParam(url, params),
                method: 'GET',
                dataType: datatype,
                beforeSend: function () {
                    YouLi.Common.UI.ShowLoading();
                },
                success: successCallback,
                error: errorCallback,
                //function (xhr, status, error)
                //{
                    //console.error(xhr.responseText + ' ' + status + ' ' + error);
                    //show oops someething went wrong modal
                //},
                complete: function () {
                    YouLi.Common.UI.HideLoading();
                }
            });
        }

        YouLi.Common.Post = function (url, params, successCallback, errorCallback, datatype = "json", contentType = "", processData = true) {

            let ajaxOptions = {
                url: url,
                method: 'POST',
                dataType: datatype,
                data: params,
                beforeSend: function () {
                    YouLi.Common.UI.ShowLoading();
                },
                success: successCallback,
                error: errorCallback,
                complete: function () {
                    YouLi.Common.UI.HideLoading();
                }
            }

            if (contentType !== "") {
                ajaxOptions.contentType = contentType;
                ajaxOptions.processData = processData;
            }

            $.ajax(ajaxOptions);
        }

        YouLi.Common.Put = function (options) {
            options.method = "PUT";
            return ajaxPromise(options);
        }

        YouLi.Common.Delete = function (options) {
            options.method = "DELETE";
            return ajaxPromise(options);
        }

        function ajaxPromise(options) {
            YouLi.Common.UI.ShowLoading();
            return $.ajax(buildAjaxOptions(options))
                .done(function (res) {
                    return res;
                })
                .fail(function (xhr, status, error) {
                    $.Deferred().reject(xhr, status, error);
                })
                .always(function () {
                    YouLi.Common.UI.HideLoading();
                });
        }

        function buildAjaxOptions(options) {
            if (typeof options !== 'object' || options === null) {
                throw new Error('The argument must be an object.');
            }

            const defaults = {
                url: null,
                params: null,
                datatype: 'json',
                contentType: "",
                processData: true
            };

            const settings = $.extend({}, defaults, options);

            let ajaxOptions = {
                url: settings.url,
                method: settings.method,
                dataType: settings.datatype,
                data: settings.params
            }

            if (settings.contentType !== "") {
                ajaxOptions.contentType = settings.contentType;
                ajaxOptions.processData = settings.processData;
            }

            return ajaxOptions;
        }

        function buildQueryParam(url, params) {
            let queryParams = [];

            $.each(params, function (key, val) {
                if (params.hasOwnProperty(key)) {
                    queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
                }
            });

            return url + '?' + queryParams.join('&');
        }


    }(YouLi.Common ? YouLi.Common : {}));
}(YouLi ? YouLi : {}));