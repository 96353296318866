var YouLi = YouLi ? YouLi : {};
YouLi.Common = YouLi.Common ? YouLi.Common : {};
YouLi.Common.Currency = YouLi.Common.Currency ? YouLi.Common.Currency : {};

(function (YouLi) {
    (function (Common) {
        (function (Currency) {

            YouLi.Common.Currency.Currencies = [
                { Code: "AUD", DisplayPrefix: "A$", HasDecimal: true },
                { Code: "USD", DisplayPrefix: "US$", HasDecimal: true },
                { Code: "CAD", DisplayPrefix: "C$", HasDecimal: true },
                { Code: "EUR", DisplayPrefix: "€", HasDecimal: true },
                { Code: "GBP", DisplayPrefix: "£", HasDecimal: true },
                { Code: "NZD", DisplayPrefix: "NZ$", HasDecimal: true },
                { Code: "JPY", DisplayPrefix: "¥", HasDecimal: false },
                { Code: "DKK", DisplayPostfix: "kr", HasDecimal: true },
                { Code: "MXN", DisplayPrefix: "M$", HasDecimal: true },
                { Code: "INR", DisplayPrefix: "INR₹", HasDecimal: true },
                { Code: "SGD", DisplayPrefix: "S$", HasDecimal: true },
                { Code: "AED", DisplayPrefix: "Dhs", HasDecimal: true }
            ];

            YouLi.Common.Currency.DisplayCost = function (currency, value) {
                var cost = "";

                if (currency.DisplayPrefix !== undefined &&  currency.DisplayPrefix !== null)
                    cost += currency.DisplayPrefix;

                if (currency.HasDecimal)
                    cost += parseFloat(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                else
                    cost += value;

                if (currency.DisplayPostfix !== undefined && currency.DisplayPostfix !== null)
                    cost += currency.DisplayPostfix;

                return cost;
            };

            YouLi.Common.Currency.GetCurrency = function (currencyCode) {
                return YouLi.Common.Currency.Currencies.find(function (obj) {
                    return obj.Code === currencyCode;
                });
            };
            
        }(YouLi.Common.Currency ? YouLi.Common.Currency : {}));
    }(YouLi.Common ? YouLi.Common : {}));
}(YouLi ? YouLi : {}));